import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Posts from "../components/posts"
import CoverBlog from "../components/cover-blog"
import AngelusTVVideo from "../videos/angelustv.mp4"

const MediasPage = () => (
  <Layout>
    <Seo title="Medias" />
    {/* <h1>Hi from the Dons page</h1>
    <p>Welcome to page Dons</p>
    <Link to="/">Go back to the homepage</Link> */}
    <CoverBlog
      img="jon-tyson-H1ZwfJSsMVk-unsplash.jpg"
      offset="40%"
      postTitle="Médias"
    />

    <section
      id="medias"
      style={{
        paddingTop: "2em",
        paddingBottom: "2em",

        // height: "calc(100vw * 9 /16)",
        // maxHeight: "1000px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          // height: "100%",
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <div style={{ width: "560px" }}>
          {/* <h2 style={{ textAlign: "center" }}>Médias</h2> */}
          <p>
            Vous pouvez retrouver ici quelques uns de mes passages et
            interventions dans des podcasts, des lives et des événements auxquel
            j'ai participé dans les derniers mois écoulés.
          </p>
          <h3>
            Interview pour la web TV chrétienne roumaine Angelus TV (FR-RO)
          </h3>
          <video controls style={{ width: "100%" }}>
            <source src={AngelusTVVideo} type="video/mp4" />
          </video>
          <h3>Podcasts</h3>
          <iframe
            src="https://anchor.fm/entre-nous-soit-dit/embed/episodes/Elle-tait-rousse-et-allemande---Entre-nous-soit-dit-avec-Sebastien-Gil-e11acql"
            title="Anchor audio player"
            height="102px"
            width="400px"
            frameborder="0"
            scrolling="no"
          ></iframe>
          <h3>Lives enseignement & exhortation</h3>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KVeSUUsxyTM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/LEa-let9duU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ZKHQZwuidU4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Yu2dJQFn1SE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <h3>
            Événement en direct (louange & traduction des intervenants en
            anglais)
          </h3>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/TQJw--Ayj_g"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/rsn963r3t0w"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <Posts lastPosts />
      </div>
    </section>
  </Layout>
)

export default MediasPage
