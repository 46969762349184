import * as React from "react"

import CoverImg from "../images/kenrick-mills-MF9Wy1NA55I-unsplash.jpg"

const CoverBlog = ({ img, offset, postTitle, postAuthor, postDate }) => {
  return (
    <section
      style={{
        backgroundImage: `${
          img === undefined
            ? `url(${CoverImg})`
            : `url(${require("../images/" + img).default})`
        }`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: `${offset === undefined ? "80%" : offset}`,
        // marginBottom: `1.45rem`,
        height: "480px",
        maxHeight: "1000px",
      }}
    >
      {/* <Img fluid={img} /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          margin: `0 auto`,
          maxWidth: "100%",
          padding: `6.75rem 1.0875rem`,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "#00000022",
          background: "linear-gradient(180deg, #00000033 0%, #00000000 35%)",
          color: "#eee",
        }}
      >
        {postTitle === undefined ? null : (
          <h1
            style={{
              textShadow: "7px 4px 0px #00000077",
              textAlign: "center",
            }}
          >
            {postTitle}
          </h1>
        )}{" "}
        <p>{postAuthor === undefined ? null : `${ postAuthor } - ${ postDate }`}</p>
      </div>
    </section>
  )
}

export default CoverBlog
